import { Article, ArticleTopic } from "types/misc"

export enum ProductAvailabilityStatus {
  Available,
  Unavailable,
  MailingList,
}

export const determineProductAvailabilityStatus = (
  variant
): ProductAvailabilityStatus => {
  if (variant.available) return ProductAvailabilityStatus.Available
  return ProductAvailabilityStatus.Unavailable
}

export const getProductWaitingListIdFromKlaviyo = (emailListId) => {
  if (!emailListId || emailListId === 'default') {
    return 'SaCRXN' // mailchimp list id, if not provided it defaults to Consumer Ad Hoc audience
  }
  return emailListId

}

export const determineBuyButtonColor = (
  productAvailabilityStatus: ProductAvailabilityStatus
) => {
  return productAvailabilityStatus == ProductAvailabilityStatus.MailingList
    ? 'green'
    : 'blue'
}

export const determineBuyButtonText = (
  productAvailabilityStatus: ProductAvailabilityStatus,
  price: number | string = 0
) => {
  let priceNumber = price
  if (typeof priceNumber == 'string') priceNumber = Number(priceNumber)
  switch (productAvailabilityStatus) {
    case ProductAvailabilityStatus.Unavailable: {
      return 'Out of Stock'
    }
    case ProductAvailabilityStatus.MailingList: {
      return 'Notify me when back in stock'
    }
    default: {
      return `${priceNumber ? `$${priceNumber.toFixed(2)} | ` : ''} Add to Cart`
    }
  }
}

export const getProductModalDisplayName = (copy, product, selectedVariant) => {
  let s = product.title
  if (copy.handle === 'waterproof-enclosure') {
    s = 'The ' + s
  }
  if (selectedVariant.title !== 'Default Title') {
    s += ' (' + selectedVariant.title + ')'
  }
  return s
}

/**
 * @src https://gist.github.com/codeguy/6684588
 * @param str i.e. (special) + Something!
 * @returns string i.e. special-something
 */
export const slugifyText = (str: string) => (
  str
    .replace(/^\s+|\s+$/g, '') // trim
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes
)

interface ArticleTopicCounted extends ArticleTopic {
  count: number
}
export const deriveMostPopularTopics = (articles: Article[], limit: number = 6): ArticleTopic[] => {
  const topics = {} as {
    [slug: string] : ArticleTopicCounted
  }

  articles.forEach(article => {
    article.topics.forEach(topic => {
      if (!topic) return;
      if (topics[topic.slug]) {
        topics[topic.slug].count = topics[topic.slug].count + 1
      } else {
        topics[topic.slug] = {
          ...topic,
          count: 1
        };
      }
    })
  })

  const mostPopularTopicSlugs = Object.keys(topics).sort((a,b) => topics[a].count > topics[b].count ? -1 : 1).slice(0, limit);

  return mostPopularTopicSlugs.map(slug => topics[slug]);
}

export const sortTopicsAlpha = (tags: ArticleTopic[]) => {
  return tags.sort((a, b) => {
    if (a.title < b.title) {
      return -1;
    }
    if (a.title > b.title) {
      return 1;
    }
    return 0;
  })
}

export const BLOG_ARTICLE_LIST_LENGTH = 12;