import React, { useEffect, useState } from "react"
import { getProductByHandle, transformShopifyStorefrontImagesToGatsbyImage } from "utilities"
import { GatsbyShopifyStorefrontProduct } from "shopify-buy"
import { filterVariantForWFT } from "../../../lib/shopify"
import { ProductCopy } from "types/misc"

type Props = {
  shopifyProductHandle?: string
  productPage: ProductCopy
  render: (product: GatsbyShopifyStorefrontCopyProduct) => JSX.Element | null
}

export interface GatsbyShopifyStorefrontCopyProduct extends GatsbyShopifyStorefrontProduct {
  copy: {
    handle: string;
    shortDescription: string;
    subtitle: string;
    seoPathname: string;
  }
}

export const GatsbyShopifyStorefrontProductLoader = ({ shopifyProductHandle, productPage, render }:  Props) => {
  const [shopifyCopyProduct, setShopifyCopyProduct] = useState<GatsbyShopifyStorefrontCopyProduct | null>(null);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const shopifyProduct = await getProductByHandle(shopifyProductHandle || productPage.handle)

        if (!shopifyProduct?.availableForSale) return;

        const gatsbyShopifyStorefrontProduct = transformShopifyStorefrontImagesToGatsbyImage({
          ...shopifyProduct,
          variants: filterVariantForWFT(shopifyProduct.variants)
        });

        if (!gatsbyShopifyStorefrontProduct) return;

        setShopifyCopyProduct({
          ...gatsbyShopifyStorefrontProduct,
          copy: productPage
        })
      } catch {
        console.error('Could not fetch Sanity Product Upsells')
      }
    }
    
    fetchProduct()
  }, [])

  return shopifyCopyProduct ? render(shopifyCopyProduct) : null;
}