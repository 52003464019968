import React, { useEffect, useState } from "react";
import { ICart, IProduct, IProductVariant } from "shopify-buy";
import { PARTNER_CONTROLLER_PRODUCT_SKUS } from "../../../../common/config/products";
import {
  fetchIdFromGlobalId,
  getGlobalVariantId,
  getProductByHandle,
  mergeSanityProductWithShopifyProduct,
  ShopifyCopyStorefrontProduct,
  transformShopifyStorefrontImagesToGatsbyImage
} from "utilities";
import {
  PickyStoryAddonSelectionMap,
  filterAvailablePickyStoryVariants,
  PickyStoryClient,
  PickyStoryDeal,
  PickyStoryProduct,
  PickyStoryProductPartial,
  PICKYSTORY_DEAL_TYPE,
  PickyStorySelectionMap,
  getVariantsFromSeletions,
  getAddonVariantsFromSelection,
  createSelectionMap,
  usePickyStoryContext,
  createAddonSelectionMap,
  deriveDiscountAmount
} from "../../../lib/pickystory";
import { BUNDLE_LAYOUTS, BundleCopyProduct, ProductCopy, SectionPaddingSize, SectionSize, SectionTheme } from "types/misc";
import { useCheckout } from "global";
import { flattenProductVariants } from "../../../lib/shopify";
import { TrackingProductUnformatted, trackProductAdded, trackProductViewed } from "analytics";
import { BundleUnavailable } from "./BundleUnavailable";
import { BundleProductListOverview } from "./BundleProductListOverview";
import { BundleProductOverview } from "./BundleProductOverview";

const trackProducts = (
  variants: { id: number, price: number }[],
  products: ShopifyCopyStorefrontProduct[],
  checkout: ICart,
  bundleName: string,
  trackingEvent: 'added' | 'viewed'
) => {
  const variantIds = variants.map(v => getGlobalVariantId(v.id));
  const trackingItems: (TrackingProductUnformatted | undefined)[] = products.map(p => {
    const variant = p.variants.find(v => variantIds.includes(String(v.id)));
    if (variant) {
      return {
        price: variant.price.amount,
        sku: variant.sku,
        variantId: variant.id,
        productId: p.id,
        title: p.title,
        quantity: 1,
        bundle: bundleName
      } as TrackingProductUnformatted
    }
  });

  trackingItems.forEach(trackingItem => {
    if (!trackingItem) return;
    if (trackingEvent === 'added') {
      trackProductAdded(trackingItem, checkout);
    } else {
      trackProductViewed(trackingItem);
    }
  });
}



type TransformedProductData = {
  products?: ShopifyCopyStorefrontProduct[],
  dealProducts?: PickyStoryProductPartial[]
};
const transformProducts = async (pickyStoryProducts: PickyStoryProduct[], copyProducts: ProductCopy[]): Promise<TransformedProductData> => {
  const products = await Promise.all(
    pickyStoryProducts.map(p => getProductByHandle(p.handle))
  )

  if (!products) {
    throw new Error('No Products Found!');
  }

  const nullProductsRemoved = products.filter(Boolean) as IProduct[];

  nullProductsRemoved.forEach(p => {
    if (!p.availableForSale) {
      throw new Error('Bundle Product Out-of-Stock');
    }
  })

  const productsFormatted = mergeSanityProductWithShopifyProduct(
    copyProducts,
    nullProductsRemoved
  )
    .map(product => {
      const productWithFormattedImages = transformShopifyStorefrontImagesToGatsbyImage(product, 600, 600) as ShopifyCopyStorefrontProduct;
      productWithFormattedImages.variants = productWithFormattedImages.variants.filter(v => v.sku !== PARTNER_CONTROLLER_PRODUCT_SKUS.WEATHERFLOW_TEMPEST_SINGLE_PURCHASE)
      return productWithFormattedImages
    })
    .filter(p => p !== null && !p.copy.excludedFromCollection)

  const flattenedProductVariants = flattenProductVariants(productsFormatted, true);

  const dealProductsFilteredVariants = pickyStoryProducts.map((p) => filterAvailablePickyStoryVariants(p, flattenedProductVariants));

  return {
    products: productsFormatted,
    dealProducts: dealProductsFilteredVariants
  };
}

type BundleDisplayProps = {
  deal: PickyStoryDeal
  pickyStoryClient: PickyStoryClient
  copyProducts: ProductCopy[]
  copy: BundleCopyProduct
  layout?: BUNDLE_LAYOUTS
  onBundleOutOfStock?: (errorMessage: string) => void
  hideTitle?: boolean
  theme?: SectionTheme,
  paddingTop?: SectionPaddingSize
  paddingBottom?: SectionPaddingSize,
  size?: SectionSize
}

enum ADD_TO_CART_STATES {
  IDLE = "IDLE",
  PENDING = "PENDING",
}

export const BundleDisplay = ({
  deal,
  copy,
  copyProducts,
  onBundleOutOfStock,
  layout,
  hideTitle,
  theme,
  paddingBottom,
  paddingTop,
  size
}: BundleDisplayProps) => {
  usePickyStoryContext();
  const [outOfStock, setOutOfStock] = useState<boolean>(false);
  const [products, setProducts] = useState<(ShopifyCopyStorefrontProduct | null)[]>([]);
  const [selectionMap, setSelectionMap] = useState<PickyStorySelectionMap>(
    createSelectionMap(deal.products)
  );
  const [addonProducts, setAddonProducts] = useState<(ShopifyCopyStorefrontProduct | null)[]>([]);
  const [addonSelectionMap, setAddonSelectionMap] = useState<PickyStoryAddonSelectionMap | null>(
    deal.addonProducts ? createAddonSelectionMap(deal.addonProducts) : null
  );
  const [addToCartStatus, setAddToCartStatus] = useState<ADD_TO_CART_STATES>(ADD_TO_CART_STATES.IDLE);

  const [subTotal, setSubTotal] = useState<number | null>(null);
  const { checkout } = useCheckout();

  const onVariantSelect = (globalProductId: string, variant: IProductVariant) => {
    const strippedProductId = Number(fetchIdFromGlobalId(globalProductId));
    const product = deal.products.find(p => p.id === strippedProductId);
    if (!product) return;
    const copySelectionMap = { ...selectionMap };

    copySelectionMap[product.id] = {
      option1: variant.selectedOptions[0].value,
      option2: variant.selectedOptions[1]?.value,
      option3: variant.selectedOptions[2]?.value,
      position: product.position,
    };

    setSelectionMap(copySelectionMap);
  }

  const onAddonVariantSelect = (globalProductId: string, variant: IProductVariant) => {
    const strippedProductId = Number(fetchIdFromGlobalId(globalProductId));
    const product = deal.addonProducts.find(p => p.id === strippedProductId);
    if (!product) return;
    const copySelectionMap = { ...addonSelectionMap } as PickyStoryAddonSelectionMap;

    copySelectionMap[product.id] = {
      id: Number(fetchIdFromGlobalId(variant.id)),
      quantity: variant.quantity,
      price: Number(variant.price.amount)
    };

    setAddonSelectionMap(copySelectionMap);
  }

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const { products, dealProducts } = await transformProducts(deal.products, copyProducts);
        if (products && dealProducts) {
          setProducts(products);
          setSelectionMap(createSelectionMap(dealProducts));
        }

        if (!deal.addonProducts || copy.bundleType !== PICKYSTORY_DEAL_TYPE.BUNDLES) return;

        const { products: addonProducts, dealProducts: addonDealProducts } = await transformProducts(deal.addonProducts, copyProducts);
        if (addonProducts && addonDealProducts) {
          setAddonProducts(addonProducts);
          setAddonSelectionMap(createAddonSelectionMap(addonDealProducts));
        }
      } catch (error) {
        const err = error as Error;
        console.error(err);
        setOutOfStock(true);
        if (typeof onBundleOutOfStock === "function") {
          onBundleOutOfStock(err.message);
        }
      }
    }

    fetchProductData();
  }, [])

  const addBundleToCart = async () => {
    try {
      const appendBundleNameAttribute = v => v.properties = {
        '_bundleName': deal.spec.name
      }
      setAddToCartStatus(ADD_TO_CART_STATES.PENDING);
      const dealVariants = getVariantsFromSeletions(deal, selectionMap);
      if (dealVariants) dealVariants.forEach(appendBundleNameAttribute)
      const addonVariants = getAddonVariantsFromSelection(addonSelectionMap);
      if (addonVariants) addonVariants.forEach(appendBundleNameAttribute);
      await deal.addVariantsToCart(dealVariants, 1, addonVariants);
      trackProducts(
        [...dealVariants, ...(addonVariants || [])],
        [...products, ...addonProducts] as ShopifyCopyStorefrontProduct[],
        checkout,
        deal.spec.name,
        'added'
      );

      /**
       * @src location.href does not work on iPad - https://stackoverflow.com/questions/26439843/window-location-href-doesnt-work-in-ipad-and-iphone
       * @src typing workaround for window.location -  https://github.com/microsoft/TypeScript/issues/48949
       */
      (window as Window).location = window.location.origin + '/cart/'
    } catch (error) {
      console.error('Could not add Bundle to Cart', error);
    }
  }

  useEffect(() => {
    const dealVariants = getVariantsFromSeletions(deal, selectionMap);
    const addonVariants = getAddonVariantsFromSelection(addonSelectionMap);
    const total = dealVariants.reduce((prev, cur) => prev + cur.price, 0);

    if (addonVariants) {
      setSubTotal(
        addonVariants.reduce((prev, cur) => prev + (cur.price * cur.quantity), total)
      )
    } else {
      setSubTotal(total);
    }

    trackProducts(
      [...dealVariants, ...(addonVariants || [])],
      [...products, ...addonProducts] as ShopifyCopyStorefrontProduct[],
      checkout,
      deal.spec.name,
      'viewed'
    );
  }, [selectionMap, addonSelectionMap]);

  const buttonText = () => {
    switch (addToCartStatus) {
      case ADD_TO_CART_STATES.PENDING:
        return "Adding to Cart..."
      default:
        return "Add to Cart"
    }
  }

  if (outOfStock) {
    return <BundleUnavailable />
  }

  if (layout === 'list' || copy.layout === "list") {
    return (
      <BundleProductListOverview
        deal={deal}
        products={products}
        addonProducts={addonProducts}
        onAddBundleToCart={addBundleToCart}
        copy={copy}
        discountAmount={deriveDiscountAmount(deal, subTotal, addonSelectionMap)}
        onVariantSelect={onVariantSelect}
        onAddonVariantSelect={onAddonVariantSelect}
        addToCartButtonText={buttonText()}
        subTotal={subTotal}
        hideTitle={hideTitle}
        theme={theme}
        paddingBottom={paddingBottom}
        paddingTop={paddingTop}
        size={size}
      />
    )
  }

  return (
    <BundleProductOverview
      products={products}
      addonProducts={addonProducts}
      onAddBundleToCart={addBundleToCart}
      onVariantSelect={onVariantSelect}
      onAddonVariantSelect={onAddonVariantSelect}
      copy={copy}
      subTotal={subTotal}
      discountAmount={deriveDiscountAmount(deal, subTotal, addonSelectionMap)}
      addToCartButtonText={buttonText()}
    />
  )
}