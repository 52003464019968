import { trackEvent, TrackFunction } from "analytics"
import { ICart } from "shopify-buy"
import { ShoppingEventInputs, SHOPPING_EVENT_NAMES } from "."
import { getTrackedPropertiesFromVariant, getProductsInCart } from "./transformers/product"
import { TrackingProductUnformatted } from "./types"

const track: TrackFunction<SHOPPING_EVENT_NAMES.PRODUCT_REMOVED, ShoppingEventInputs[SHOPPING_EVENT_NAMES.PRODUCT_REMOVED]> = async (
  eventName,
  properties
) => {
  await trackEvent(eventName, properties);
}

export const trackProductRemoved = async (
  variant: TrackingProductUnformatted,
  checkout: ICart
) => {
  if (!variant || !checkout) return;

  await track(
    SHOPPING_EVENT_NAMES.PRODUCT_REMOVED, 
    {
      cart_id: checkout.id,
      ...getTrackedPropertiesFromVariant(variant),
      products: getProductsInCart(checkout),
    }
  )
}