import React, { useEffect, useRef } from "react";
import { useOnScreen, useUserAgentDetection } from 'hooks'

type Props = {
  title: string
  videoMp4Src: string,
  videoWebmSrc?: string,
  enableControls?: boolean
  posterImage: {
    asset: {
      url: string
    }
  }
}
export const Video = ({ title, videoMp4Src, videoWebmSrc, enableControls, posterImage }: Props) => {
  const userAgent = useUserAgentDetection();
  const ref = useRef<HTMLVideoElement>(null)
  const isVisible = useOnScreen(ref)

  const getVideoOptions = () : React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement> => {
    const defaultSettings = {
      playsInline: true,
      autoPlay: false,
      poster: posterImage.asset.url
    }
    if (Boolean(enableControls)) {
      return {
        controls: true,
        ...defaultSettings
      }
    }
    return {
      ...defaultSettings,
      controls: false,
      muted: true,
      loop: true,
      autoPlay: userAgent.isiOS,
    }
  }

  useEffect(() => {
    if (isVisible && ref.current && !Boolean(enableControls)) {
      ref.current.play();
    }
  }, [isVisible]);

  return (
    <video
      {...getVideoOptions()}
      ref={ref}
      title={title}
    >
      <source src={videoMp4Src} type="video/mp4" />
      {videoWebmSrc && <source src={videoWebmSrc} type="video/webm" />}
    </video>
  )
}