import { trackEvent, TrackFunction } from "analytics"
import { ICart } from "shopify-buy"
import { ShoppingEventInputs, SHOPPING_EVENT_NAMES } from "."
import { getProductsInCart } from "./transformers/product"

const track: TrackFunction<
  SHOPPING_EVENT_NAMES.CART_VIEWED,
  ShoppingEventInputs[SHOPPING_EVENT_NAMES.CART_VIEWED]
> = async (
  eventName,
  properties
) => {
  await trackEvent(eventName, properties);
}

export const trackCartViewed = async (checkout: ICart | undefined) => {
  if (!checkout?.lineItems?.length) return;
  
  const cart_id = checkout?.id;

  if (!cart_id) return

  await track(
    SHOPPING_EVENT_NAMES.CART_VIEWED, 
    {
      cart_id,
      products: getProductsInCart(checkout),
      value: Number(checkout.totalPrice.amount),
      currency: checkout.currencyCode
    }
  )
}