import { Collection, Product, IProductVariant, IProduct } from 'shopify-buy';
import {
  PARTNER_PRODUCT_HANDLES,
  PARTNER_PRODUCT_VARIANTS_BY_TITLE,
  PARTNER_CONTROLLER_PRODUCT_SKUS
} from '../../../common/config/products';

export const filterCollectionProductVariantsByTitle = (collection: Collection, titleFilter = PARTNER_PRODUCT_VARIANTS_BY_TITLE.WEATHERFLOW_TEMPEST.BUNDLE) => {
  const formattedCollection = {...collection};
  const formattedProducts: Product[] = [];

  for (const product of formattedCollection.products) {
    if (PARTNER_PRODUCT_HANDLES.WEATHERFLOW_TEMPEST === product.handle) {
      const formatProduct = {...product}


      formatProduct.variants = product.variants.filter(v => v.title !== titleFilter)

      formattedProducts.push(formatProduct)
    } else {
      formattedProducts.push(product);
    }
  }

  formattedCollection.products = formattedProducts;
  return formattedCollection;
}

export const filterVariantsBySku = (variants: IProductVariant[], filterSku?: string): number => {
  if (filterSku) {
    const index = variants.findIndex((v: IProductVariant) => v.sku === filterSku);
    if (index === -1) return 0;
    return index
  }

  return 0;
}

export const filterVariantForWFT = (variants: IProductVariant[]) => {
  return variants.filter(v => v.sku !== PARTNER_CONTROLLER_PRODUCT_SKUS.WEATHERFLOW_TEMPEST_BUNDLE)
}

export type ProductLike = {
  variants: IProductVariant[]
}
export const flattenProductVariants = (products: ProductLike[], onlyAvailableVariants?: boolean) => (
  products.reduce((prev, current) => {
    if (onlyAvailableVariants) {
      current.variants = current.variants.filter(v => v.available);
    }
    if (current === null) return prev;
    return [...prev, ...current.variants]
  }, [] as IProductVariant[])
)
