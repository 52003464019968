import { IProductVariant } from "shopify-buy";

export const roundPrice = (price: number) => (Math.round(price * 10) / 10)

export const discountPrice = (promo: { amount: number, percentage: number }, price: number, round: boolean = false): number => {
  if (promo.percentage) {
    const discounted = price * (promo.percentage / 100);
    return round ? roundPrice(price - discounted) : price - discounted;
  }

  if (promo.amount) {
    return price - promo.amount;
  }

  return price;
}

export const getDiscountedAmount = (
  basePrice: number,
  discountType: "amount" | "percentage",
  discountValue: number
) => {
  if (discountType === "amount") {
    return discountValue;
  }

  if (discountType === "percentage") {
    return basePrice * (discountValue / 100);
  }

  return 0;
}

export const getBasePrice = (variant: IProductVariant) => {
  if (variant.compareAtPrice) return Number(variant.compareAtPrice.amount)
  return Number(variant.price.amount)
}