import { useEffect } from "react";
import { IProductVariant } from "shopify-buy";
import { filterVariantsBySku } from "../../lib/shopify";
import { getUrlParam, getGlobalVariantId } from "utilities";

type Props = { 
  productPage: { filterSku?: string }, 
  product: { variants: IProductVariant[] },
  setSelectedVariant: (variant: IProductVariant) => any
}

export const useProductVariantSelection = ({ 
  productPage, 
  product,
  setSelectedVariant
}: Props) => {
  useEffect(() => {
    if (productPage.filterSku) {
      return setSelectedVariant(
        product.variants[filterVariantsBySku(product.variants, productPage.filterSku)]
      )
    }
    
    const variantIdParam = getUrlParam('variant');
    if (variantIdParam) {
      const variantId = getGlobalVariantId(variantIdParam);
      const variant = product.variants.find(v => v.id === variantId && v.available);
      if (variant) return setSelectedVariant(variant);
    }
    
    setSelectedVariant(
      product.variants.find(v => v.available) || product.variants[0]
    )
  }, [product])
}