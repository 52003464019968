import React from 'react'
import { Wrapper } from 'styles'
import { ProductOverview } from 'components/product'
import { transformShopifyStorefrontImagesToGatsbyImage } from 'utilities'
import styled from 'styled-components'
import { ProductCopy } from 'types/misc'
import { useFetchShopifyProductByHandle } from 'hooks'

type Props = {
  initialShopifyProduct: any
  productPage: ProductCopy
}

const SanityShopifyProduct = ({ productPage }: Props) => {
  const shopifyProduct = useFetchShopifyProductByHandle(productPage.handle)

  if (!shopifyProduct) return null;

  const productTransformed = transformShopifyStorefrontImagesToGatsbyImage(shopifyProduct);

  if (!productTransformed) return null;

  return (
    <div>
      <ProductWrapper>
        <ProductOverview
          product={productTransformed}
          productPage={productPage}
          productImages={productTransformed.images}
        />
      </ProductWrapper>
    </div>
  )
}

const ProductWrapper = styled(Wrapper)`
  padding-top: 4rem;
`

export default SanityShopifyProduct
