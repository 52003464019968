import Client, { Collection, IProduct } from 'shopify-buy'

export const clientShopify = Client.buildClient({
  domain: `${process.env.GATSBY_SHOPIFY_STORE_NAME}.myshopify.com`,
  storefrontAccessToken: String(process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN),
})

export const getProductById = async (id: string): Promise<IProduct | null> => {
  try {
    return await clientShopify.product.fetch(id)
  } catch {
    return null
  }
}

export const getProductByHandle = async (handle: string): Promise<IProduct | null> => {
  try {
    return await clientShopify.product.fetchByHandle(handle)
  } catch {
    return null
  }
}

export const getProductsByHandles = async (handles: string[]) => {
  const products = [] as IProduct[]

  for (const handle of handles) {
    const product = await getProductByHandle(handle);
    if (product) products.push(product);
  }

  return products;
}


export const getCollectionByHandle = async (
  handle: string
): Promise<Collection | null> => {
  try {
    const collection = await clientShopify.collection.fetchByHandle(handle);
    if (!collection) return null;
    return await clientShopify.collection.fetchWithProducts(collection.id, {productsFirst: 100})
  } catch {
    return null
  }
}
