import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { CollectionCardContainer } from './CollectionCard'
import { Button } from '@rachio-npm/rachio-retail-common'

declare global {
  interface Window {
    Extend: {
      modal: {
        open: any
      }
    };
    ExtendShopify: any
  }
}

export type CalloutCardProps = {
  headline: string
  description?: string
  image?: {
    asset: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  link?: string
  buttonText?: string
  children?: JSX.Element
  id?: string
  hideOnMobile?: boolean
}

const Header = ({
  headline,
  link,
  image
}: {
  headline: string,
  link?: string,
  image?: {
    asset: {
      gatsbyImageData: IGatsbyImageData
    }
  }
}) => {
  return (
    <>
      {image  && <GatsbyImage
        image={image.asset.gatsbyImageData}
        alt={headline}
        objectFit="contain"
      />}
      <Title image={image}>
        {
          link ? (
            <Link to={link as string}>
              <div className="title-wrapper">
                <h2 className={`product-title ${!image ? 'title-large' : ''}`}>
                  {headline}
                </h2>
              </div>
            </Link>
          ) : (
            <div className="title-wrapper">
              <h2 className={`product-title ${!image ? 'title-large' : ''}`}>
                {headline}
              </h2>
            </div>
          )
        }
      </Title>
    </>
  )
}

export function CollectionCalloutCard({
  headline,
  description,
  image,
  link,
  buttonText,
  children,
  id,
  hideOnMobile = true
}: CalloutCardProps) {

  return (
    <CollectionCalloutCardContainer
      hideOnMobile={hideOnMobile}
      data-testid="product-preview-callout-card"
      className="product-preview"
      id={id ? id : ''}
    >
      <div className="product-wrapper">
        <div>
          <div>
            {headline && (
              <Header
                headline={headline}
                link={link}
                image={image}
              />
            )}

            {description && <p className="product-description">{description}</p>}
          </div>
          <div className="pricing">
            {children && <div className='content-wrapper'>{children}</div>}
            {
              buttonText && link
              ? (
                <ButtonWrapper>
                  <Button
                    className="buy-button"
                    as={Link}
                    color="white"
                    to={link}
                  >
                    {buttonText}
                  </Button>
                </ButtonWrapper>
              )
              : null
            }
          </div>
        </div>
      </div>
    </CollectionCalloutCardContainer>
  )
}

const CollectionCalloutCardContainer = styled(CollectionCardContainer) <{ cardHeight?: number, hideOnMobile?: boolean }>`
  .product-wrapper {
    height: 100%;
    background: var(--navyBlue);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: none;
    .product-title, .product-description {
      color: white;
      text-align: center;
    }
    .product-title {
      padding: 0 15px;
      margin-top: 15px;
      min-height: 35px;
    }
    .pricing {
      min-height: initial;
      justify-content: center;
    }
    .content-wrapper {
      color: white;
    }
  }
  ${({ hideOnMobile }) => hideOnMobile && css`
    @media(max-width: 1080px) {
      display: none;
    }
  `}

  @media (max-width: 768px) {
    .product-title {
      margin-bottom: 0;
    }
  }
`

const ButtonWrapper = styled.div`
  .buy-button {
    font-size: 12px;
    padding: 10px 20px;
  }

  @media (max-width: 768px) {
    .buy-button {
      padding: 5px 20px;
    }
  }

  @media (max-width: 768px) {
    .buy-button {
      padding: 5px 10px;
    }
  }
`

export const Title = styled.div<{ image: any }>`
  .title-wrapper {
    text-align: center;
    ${({ image }) => {
      if (!image) {
        return css`
          h2.product-title {
            font-size: var(--h4);
            @media (max-width: 480px) {
              font-size: var(--h5);
            }
          }
        `
      } 

      return css`
        h2.product-title {
          font-size: 'var(--calloutFontSize)';
        }
      `
    }}
  }
`