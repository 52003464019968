import { ICart } from "shopify-buy"

export interface PickyStorySelection {
  option1: string,
  option2?: string,
  option3?: string,
  position: number,
  quantity?: number
}

export interface PickyStoryAddonSelection {
  id: number,
  quantity?: number
}

export interface PickyStoryVariant {
  // stripped shopify variant id: 41924475158706
  id: number
  image: {
    alt?: string
    id?: string
    src?: string
  }
  option1: string
  option2?: string
  option3?: string
  position: number
  price: number
  sku: string
  title: string
  quantity?: number
  properties?: {
    [name: string]: any
  }
}

export interface PickyStoryImage {
  alt?: string
  src: string
  height?: number
  width?: number
}

export interface PickyStoryOption {
  // stripped shopify variant id: 41924475158706
  id: number
  name: string
  position: number
  swatchMode?: string
  swatchValues?: any
}

export interface PickyStoryProduct {
  // stripped shopify variant id: 41924475158706
  id: number
  allowedVariants?: PickyStoryVariant[]
  handle: string
  description: string
  position: number
  title: string
  variants: PickyStoryVariant[]
}

type PickyStoryDiscountTier = {
  label: string,
  quantity: number,
  discount: {
    value: number,
    valueType: DISCOUNT_TYPE
  }
}

export interface PickyStorySpec {
  bulkOption?: string
  maxRetailPrice: number
  name: string
  maxQuantity: number
  discountValue: number
  discountValueType: string
  discountIsEnabled: boolean
  discountCodePrefix: string
  discount?: {
    prerequisiteQuantity?: number
  }
  slug: string
  position: number
  tiers?: PickyStoryDiscountTier[]
}

export enum DISCOUNT_TYPE {
  PERCENTAGE = 'percentage',
  FIXED_AMOUNT = 'fixedAmount'
}

export interface PickyStoryDeal {
  id: string
  addToCartStrategy: "children" | "parent"
  addonProducts: PickyStoryProduct[]
  products: PickyStoryProduct[]
  category: string
  featuredImage?: PickyStoryImage
  discountType: "percentage" | "fixedAmount"
  discountValue: number
  maxQuantity: number
  allowedOptions: PickyStoryOption[]
  containerProduct: PickyStoryProduct
  addVariantsToCart: (variants: { quantity?: number, id: number, position: number }[], quantity: number, addons?: { id: number, quantity?: number }[]) => any
  getVariantsForSelections: (selections: PickyStorySelection[]) => PickyStoryVariant[]
  validateSelections: (selections: PickyStorySelection[]) => boolean
  spec: PickyStorySpec
}

export interface PickyStoryConfig {
  api: {
    storefront: {
      useCheckoutAsCart: boolean,
      enable: boolean,
      // Shopify Storefront API token (make sure to re-use the same token that is used for the js-buy-sdk!)
      token: string
    }
  }
  discountDebugToken: string
}

export interface PickyStoryLocation {
  allDeals: PickyStoryDeal[]
  deals?: PickyStoryDeal[]
  isActive: boolean
  name: string
  id: string
}

export type PickyStorySetStorefrontOptions = (options: { checkoutId: string }) => Promise<void>

export type PickyStoryDealTypes = "combos" | "bundles" | "builders" | "kits";

export enum PICKYSTORY_DEAL_TYPE {
  COMBOS = 'combos',
  BUNDLES = 'bundles',
  BUILDERS = 'builders'
}

export interface PickyStoryClient {
  categories: PickyStoryDealTypes[]
  getLocations: (dealType: PickyStoryDealTypes, onlyActive?: boolean) => PickyStoryLocation[] | []
  setStorefrontOptions: PickyStorySetStorefrontOptions
  refreshCart: () => Promise<void>
  getLastShopifyCheckout: () => Promise<ICart>
}

export type PickyStoryProductPartial = {
  id: number,
  position: number,
  variants: PickyStoryVariant[]
}

export type PickyStoryDiscountDetails = {
    code: string
    applications: {
      reason: PickyStoryDealTypes,
      discountValue: number,
      discountValueType: string
      items: DiscountItemEvent[]
    }[]
}

export type PickyStoryDiscountEvent = {
  discount: PickyStoryDiscountDetails
}

export type DiscountItemEvent = {
  id: string,
  quantity: number
}

export interface PricedPickyStoryAddonSelection extends PickyStoryAddonSelection {
  price: number
}

export type PickyStorySelectionMap = {
  [productId: number]: PickyStorySelection
}

export type PickyStoryAddonSelectionMap = {
  [productId: number]: PricedPickyStoryAddonSelection
}