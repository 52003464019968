
import { ICart, ILineItem } from "shopify-buy"

import { PRODUCT_CATEGORY, SKU_NAME_MAP } from "./sku-name-map"
import { TrackingProduct, TrackingProductUnformatted } from "../types"
import { fetchIdFromGlobalId } from "utilities"

export const getTrackedPropertiesFromVariant = (
  variant: TrackingProductUnformatted
): TrackingProduct => {
  let name = SKU_NAME_MAP[variant.sku]
    ? SKU_NAME_MAP[variant.sku].name
    : variant.title

  const category = SKU_NAME_MAP[variant.sku]
    ? SKU_NAME_MAP[variant.sku].category
    : PRODUCT_CATEGORY.MARKET

  const properties = {
    product_id: fetchIdFromGlobalId(variant.productId),
    variant: fetchIdFromGlobalId(variant.variantId),
    sku: variant.sku,
    name,
    price: Number(variant.price),
    quantity: variant.quantity || 1,
    category,
  } as TrackingProduct

  if (variant.bundle) properties.bundle = variant.bundle;
  return properties
}

export const getProductsInCart = (cart: ICart): TrackingProduct[] | undefined => {
  if (cart && cart.lineItems) {
    const products = cart.lineItems.map(
      (lineItem: ILineItem) => {
        const variantId = String(lineItem.variant.id);
        const productId = String(lineItem.variant.product.id);
        const variant: TrackingProductUnformatted = {
          ...lineItem.variant,
          variantId: String(variantId),
          productId: String(productId),
          price: lineItem.variant.price.amount,
        }
        const product = getTrackedPropertiesFromVariant({
          ...variant,
          quantity: lineItem.quantity,
        })

        return product
      }
    )

    return products
  }
}