import { getGAClientId, getRachioAccountEmail } from "analytics"
import { CustomAttribute } from "shopify-buy"
import { CheckoutRequiredTrackingProps } from "../types";

export const checkoutCustomAttributes = ({
  checkoutId,
  gaClientId = getGAClientId(),
  rachioAccountEmail = getRachioAccountEmail()
}: CheckoutRequiredTrackingProps,
  newAttributes?: CustomAttribute[]
): CustomAttribute[] => {
  let attrs = [
    {
      key: 'ga_client_id',
      value: gaClientId,
    },
    {
      key: 'checkout_id',
      value: checkoutId,
    },
  ]

  if (rachioAccountEmail) {
    attrs.push({
      key: 'rachio_account_email',
      value: rachioAccountEmail
    })
  }

  if (newAttributes) {
    attrs = [...attrs, ...newAttributes];
  }

  return attrs.filter(({ value }) => value)
}