import { Icon } from "components/elements";
import { Price } from "components/product";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useDiscount } from "global";
import React from "react";
import styled from "styled-components";
import { CustomerStory } from "types/misc";
import { ShopifyCopyStorefrontProduct, getBasePrice, sortVariantsByPrice } from "utilities";

const customerStoryProductsQuery = graphql`
  query CustomerStoriesProductsQuery {
    allShopifyProduct(
      filter: {handle: {in: ["rachio-3", "rachio-smart-hose-timer", "tempest-rachio-wx-system-1"]}}
    ) {
      edges {
        node {
          id
          title
          handle
          variants {
            price
            compareAtPrice
            sku
          }
          media {
            ... on ShopifyMediaImage {
              id
              image {
                gatsbyImageData(
                  placeholder: TRACED_SVG
                  formats: [WEBP, AUTO]
                  width: 200
                  height: 200
                )
                altText
                originalSrc
              }
            }
          }
        }
      }
    }
  }
`

const ProductCard = ({ product }: { product: ShopifyCopyStorefrontProduct }) => {
  const { getDiscountAmount } = useDiscount();
  if (!product) return null;

  return (
    <div className="product-card">
      <Link to={product.copy.seoPathname} title={product.title}>
        <GatsbyImage 
          image={product.images[0].gatsbyImageData}
          alt={`customer story product ${product.title}`}
        />
      </Link>
      
      <div className="product-card-details">
        <Link title={product.title} to={product.copy.seoPathname}>{product.title}</Link>
        <div className='pricing'>
          <span>Starting at</span>
          <Price
            basePrice={getBasePrice(product.variants[0])}
            discountAmount={getDiscountAmount(product.variants[0])}
            type='tiny'
          />
        </div>
      </div>
    </div>
  )
}

type Props = {
  customerStory: CustomerStory,
  layout: 'cards' | 'icons'
}
export const CustomerStoryProductList = ({ customerStory, layout }: Props) => {
  const { allShopifyProduct,  } = useStaticQuery(customerStoryProductsQuery);

  const productMap = {
    'rachio-3': {
      name: 'Rachio 3',
      icon: <Icon name="r3-alt" color="white" />
    },
    'rachio-smart-hose-timer': {
      name: 'Smart Hose Timer',
      icon: <Icon name="smart-hose-timer" color="white" />
    },
    'tempest-rachio-wx-system-1': {
      name: 'Tempest Weather System',
      icon: <Icon name="weatherflow-tempest" color="white" />
    }
  }

  const shopifyProductsFiltered = allShopifyProduct.edges
    .map(({ node }) => ({
      ...node,
      images: node.media.filter(mediaItem => mediaItem.image).map(img => img.image),
      copy: customerStory.products.find(pf => pf.handle === node.handle),
      variants: sortVariantsByPrice(node.variants.map(v => ({ 
        ...v, 
        price: { 
          amount: v.price 
        },
        compareAtPrice: v.compareAtPrice && {
          amount: v.compareAtPrice
        }
      })))
    }))
    .filter(p => Boolean(productMap[p.handle] && customerStory.products.find(cp => cp.handle === p.handle)))
    .sort((a, b) => {
      const handleA = a.handle.toUpperCase();
      const handleB = b.handle.toUpperCase();
      return (handleA < handleB) ? -1 : (handleA > handleB) ? 1 : 0;
    });

  return (
    <Container className={`${layout}`}>
      {layout === 'icons' 
        ? (
          <div className="product-icons">
            {shopifyProductsFiltered.map(p => (
              <div className="product-icon" key={`icon-${p.handle}`}>
                <Link title={p.title} to={p.copy.seoPathname}>{productMap[p.handle].icon}</Link>
              </div>
            ))}
          </div>
        ) : (
          <div className="product-cards">
            {shopifyProductsFiltered.map(p => (
              <ProductCard product={p} key={`product-card-${p.handle}`} />
            ))}
          </div>
        )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  svg {
    width: 50px;
    height: 50px;
  }
  &.cards {
    .product-card {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      .gatsby-image-wrapper {
        width: 6rem;
        border-radius: 50%;
      }
      .product-card-details {
        font-size: 1.2rem;
        a {
          font-size: 1.5rem;
        }
        margin-left: 1rem;
        .pricing {
          display: flex;
          align-items: center;
          .price, 
          .discount {
            font-size: 1.2rem;
            span:last-child {
              margin-right: 0;
            }
          }
          .price-text {
            display: flex;
          }
        }
        span {
          margin-right: .5rem;
        }
      }
    }
  }
  &.pills {
    svg {
      width: 25px;
      padding: 0;
      margin: 0;
      height: 1.4rem;
      position: relative;
      top: .2rem;
    }
    a {
      background: var(--accentColor);
      color: white;
      padding: .5rem 1.5rem;
      border-radius: 5rem;
      font-size: 1.4rem;
      margin: 1rem 0;
    }
  }
`
