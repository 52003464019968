import React from "react";
import { CONTROLLER_PRODUCT_SKUS } from "../../../common/config/products";
import { IProductVariant } from "shopify-buy";
import { Button, Icon } from "components/elements";
import styled from "styled-components";
import { trackButtonClicked } from "analytics";
import { fetchIdFromGlobalId } from "utilities";

type Props = {
  selectedVariant: IProductVariant
  url: string
  isController?: boolean
  buttonText?: string
}
export const BuyWithAmazonButton = ({ selectedVariant, isController, url, buttonText = 'Buy on Amazon' }: Props) => {
  const AMAZON_IDENTIFICATION_NUMBERS = {
    [CONTROLLER_PRODUCT_SKUS.FOUR_ZONE]: 'B0BCHF917X',
    [CONTROLLER_PRODUCT_SKUS.EIGHT_ZONE]: 'B07CZ864Y9',
    [CONTROLLER_PRODUCT_SKUS.SIXTEEN_ZONE]: 'B07CZ5K355'
  }

  const URL = isController 
    ? `${url}/dp/${AMAZON_IDENTIFICATION_NUMBERS[selectedVariant.sku]}`
    : url;


  return (
    <AmazonButton 
      color="blue"
      type="submit"
      onClick={() => {
        trackButtonClicked({ variant: fetchIdFromGlobalId(selectedVariant.id) } as any, 'Product', 'Amazon Button Clicked')
        window.open(URL, '_blank')
      }}
    >
      <span>
        <Icon name="amazon" color="white" />
        {buttonText}
      </span>
    </AmazonButton> 
  )
}

const AmazonButton = styled(Button)`
  margin-top: 1rem;

  padding: .8rem 2.3rem .8rem;

  svg {
    width: 25px;
    height: 25px;
    margin-right: .5rem;
    position: relative;
    top: 2px;
  }
  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`