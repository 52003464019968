import { CollectionCardContainer } from "components/collection"
import styled from "styled-components"
import { below } from "styles"

export const BundleCollectionCard = styled(CollectionCardContainer)<{ hidePrice: boolean }>`
  .variant-select-input {
    margin-bottom: 0;
  }

  .product-single-variant {
    border: none;
  }

  .variant-text {
    font-size: 16px;
  }

  .pricing > div {
    width: 100%;
  }

  .product-selection-wrapper.quantity-selection-enabled {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    select {
      min-height: 54px;
      font-size: 14px;
      padding-right: 30px;
    }
    .quantity-input-wrapper {
      margin: 15px 5px 0 10px;
      .quantity-title {
        display: none;
      }
      .quantity {
        margin-right: 10px;
      }
    }
  }

  ${below.small`
    .product-wrapper .product-description, .product-title-wrapper {
      min-height: auto;
    }

    .product-single-variant {
      display: none;
    }

    .product-selection-wrapper.quantity-selection-enabled {
      display: block;
    }
  `}

  ${({ hidePrice }) => {
    if (!hidePrice) {
      return `
        .product-single-variant {
          position: relative;
          top: 15px;
          padding-bottom: 20px;
        }
        .variant-text {
          display: none;
        }
      `
    } else {
      return `
        .product-single-variant {
          position: relative;
          top: 10px;
          padding: 1.5rem 2rem;
        }
      `
    }
  }}
`