import React from "react";
import { Button } from "@rachio-npm/rachio-retail-common"
import { useDiscount } from "global"
import { IProductVariant } from "shopify-buy"
import styled from "styled-components"
import { Price } from "./Price"
import { getBasePrice } from "utilities";

type Props = {
  title: string,
  disclaimer?: string,
  selectedVariant: IProductVariant
  firstVariant: IProductVariant
  secondVariant: IProductVariant
  setSelectedVariant: (variant: IProductVariant) => any
  firstVariantButtonText?: string
  secondVariantButtonText?: string
}
export const VariantSelectorSwitch = ({ 
  title,
  disclaimer,
  selectedVariant, 
  firstVariant, 
  secondVariant, 
  setSelectedVariant,
  firstVariantButtonText = 'Yes',
  secondVariantButtonText = 'No'
}: Props) => {
  const { getDiscountAmount } = useDiscount();

  return (
    <Container>
      <div className='center-text' data-testid="variant-selector-switch">
        <p className="variant-selector-switches-title no-bottom-margin">{title}</p>
        {disclaimer && (
          <small className='variant-selector-switches-disclaimer'>
            {disclaimer}
          </small>
        )}
      </div>
      
      <div className='variant-selector-switches'>
        <Button 
          data-testid="variant-switch-button-product-1"
          onClick={() => setSelectedVariant(firstVariant)}
          className={`variant-selector-switch ${selectedVariant.sku === firstVariant.sku ? 'active': ''}`}
        >
            {firstVariantButtonText}
        </Button>
        <Button 
          data-testid="variant-switch-button-product-2"
          onClick={() => setSelectedVariant(secondVariant)}
          className={`variant-selector-switch ${selectedVariant.sku === secondVariant.sku ? 'active' : ''}`}
        >
            {secondVariantButtonText}
        </Button>
      </div>
      <Price 
        basePrice={getBasePrice(selectedVariant)}
        discountAmount={getDiscountAmount(selectedVariant)}
      />
    </Container>    
  )
}

const Container = styled.div`
  margin-bottom: 20px;
  text-align: center;

  .variant-selector-switches-title {
    font-weight: bold;
    font-size: 20px;
  }

  .variant-selector-switches {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    button:nth-child(2) {
      margin-left: 10px;
      margin-top: 0;
    }
  }

  .variant-selector-switches-disclaimer {
    display: block;
    max-width: 480px;
    margin: .5rem auto 1.5rem;
  }

  .variant-selector-switch {
    border-color: var(--darkNavy);
    
    padding: 10px 0;
    width: 70px;
    background: transparent;
    color: var(--darkNavy);
    &.active {
      padding: 10px 0;
      width: 70px;
      background: var(--darkNavy);
      color: white;
    }
  }

  .price-text {
    display: flex;
    justify-content: center;
  }

  .price, .discount {
    font-size: var(--h4);
    line-height: 35px;
  }

  .discount {
    margin-left: 5px;
  }
`