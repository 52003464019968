import HeadlineModal from "components/elements/HeadlineModal";
import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import { fetchDeal, usePickyStoryContext } from "../../../lib/pickystory";
import { BUNDLE_LAYOUTS, BundleCopyProduct } from "types/misc";
import { BundleDisplay } from "../bundles";
import { UpsellLink } from "./UpsellLink";
import styled from "styled-components";
import { below } from "styles";
import { useWindowWidth } from "hooks";

type Props = {
  bundleUpsellCtaText: string
  bundleUpsellCtaButtonText?: string
  bundleUpsellNavigateToBundlePage?: boolean
  title?: string
  description?: string
  bundleUpsellProduct: BundleCopyProduct
}
export const BundleUpsell = ({ 
  bundleUpsellCtaText, 
  bundleUpsellCtaButtonText, 
  bundleUpsellProduct,
  bundleUpsellNavigateToBundlePage
}: Props) => {
  const { pickyStoryClient } = usePickyStoryContext();
  const [outOfStock, setOutOfStock] = useState<boolean>(false);
  const windowWidth = useWindowWidth();

  const { allSanityProductPage } = useStaticQuery(
    graphql`
      query bundleUpsellQuery {
        allSanityProductPage {
          edges {
            node {
              ...sanityProductCopy
            }
          }
        }
      }
    `
  )

  if (bundleUpsellNavigateToBundlePage) {
    return (
      <>
        {bundleUpsellProduct && bundleUpsellCtaText && bundleUpsellCtaButtonText && (
          <UpsellLink
            link={bundleUpsellProduct.seoPathname}
            text={bundleUpsellCtaText}
            buttonText={bundleUpsellCtaButtonText}
          />
        )}
      </>
    ) 
  }

  if (!pickyStoryClient) return null;

  const deal = fetchDeal(
    pickyStoryClient,
    bundleUpsellProduct.bundleType,
    bundleUpsellProduct.dealId,
    true
  );

  // We need to only run this in the browser to prevent a 404-flash on client-side routes
  // See: https://github.com/gatsbyjs/gatsby/issues/5329#issuecomment-633855927
  if (deal && !outOfStock) {
    return (
      <BundleUpsellModalContainer>
        <HeadlineModal
          title={bundleUpsellCtaText}
          buttonText={bundleUpsellCtaButtonText || 'Learn more'}
          modalMaxWidth={windowWidth && windowWidth <= 1600 ? '95%' : '60%'}
          modalContent={() => (
            <>
              <BundleDisplay
                layout={BUNDLE_LAYOUTS.LIST}
                deal={deal}
                pickyStoryClient={pickyStoryClient}
                copy={bundleUpsellProduct}
                copyProducts={allSanityProductPage.edges.map(p => p.node)}
                onBundleOutOfStock={() => setOutOfStock(true)}
                hideTitle={true}
              />
            </>
          )}
        />
      </BundleUpsellModalContainer>
    )
  } 

  return null;
}

const BundleUpsellModalContainer = styled.div`
  .headline-modal {
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .modal-text {
    margin-right: 5px;
  }

  ${below.mediumLarge`
    .inner-modal {
      max-width: 95% !important;
    }
  `}

  
`