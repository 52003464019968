import React, { createContext, FC, useContext } from 'react'
import { usePickyStory } from './usePickyStory'
import { PickyStoryClient, PickyStoryDiscountDetails } from './types'
import { ICart } from 'shopify-buy'

type Props = {
  pickyStoryDiscountDetails?: PickyStoryDiscountDetails,
  pickyStoryClient?: PickyStoryClient
}

const initValues: Props = {
  pickyStoryDiscountDetails: undefined,
  pickyStoryClient: {
    categories: [],
    setStorefrontOptions: async () => {},
    getLastShopifyCheckout: async () => ({} as ICart),
    refreshCart: async () => {},
    getLocations: () => []
  }
}

const PickyStoryContext = createContext(initValues)

export const PickyStoryProvider: FC = ({ children }) => {
  const {
    pickyStoryDiscountDetails,
    pickyStoryClient
  } = usePickyStory();
  return (
    <PickyStoryContext.Provider value={{ pickyStoryDiscountDetails, pickyStoryClient }}>
      {children}
    </PickyStoryContext.Provider>
  )
}

export const usePickyStoryContext = () => useContext(PickyStoryContext)
