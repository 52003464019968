export type CheckoutRequiredTrackingProps = {
  checkoutId: string,
  gaClientId?: string
  rachioAccountEmail?: string
}

export type TrackingProduct = {
  product_id: string;
  variant: string;
  sku: string;
  name: any;
  price: number;
  quantity?: number;
  category?: any;
  pathname?: string
  bundle?: string
}

/**
 * EX
 * {
    "shopifyId": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTk3OTk1NTU1MjI5MQ==",
    "title": "Rachio 3",
    "price": "199.99",
    "sku": "8ZULW-C",
    "quantity": 1
  }
 */
export type TrackingProductUnformatted = {
  productId: string
  variantId: string
  title: string
  price: string
  sku: string
  quantity?: number
  bundle?: string
}

export enum SHOPPING_EVENT_NAMES {
  PRODUCT_VIEWED = 'Product Viewed',
  PRODUCT_REMOVED = 'Product Removed',
  PRODUCT_ADDED = 'Product Added',
  CART_VIEWED = 'Cart Viewed',
  PRODUCT_SHARED = 'Product Shared',
}

export type ProductViewedEvent = {
  eventName: SHOPPING_EVENT_NAMES.CART_VIEWED,
  properties: TrackingProduct
}

export type ProductSharedProps = {
  title: string
  url: string
  type: string
}

export type ProductSharedEvent = {
  eventName: SHOPPING_EVENT_NAMES.PRODUCT_SHARED,
  properties: ProductSharedProps
}

interface ProductUpdateProps extends TrackingProduct {
  cart_id: string,
  products: TrackingProduct[] | undefined
}
export type ProductAddedEvent = {
  eventName: SHOPPING_EVENT_NAMES.PRODUCT_ADDED,
  properties: ProductUpdateProps
}

interface CartViewedProps {
  cart_id: string,
  products: TrackingProduct[] | undefined
  value: number
  currency: string
}
export type CartViewedEvent = {
  eventName: SHOPPING_EVENT_NAMES.CART_VIEWED,
  properties: CartViewedProps
}

export interface ShoppingEventInputs {
  [SHOPPING_EVENT_NAMES.PRODUCT_ADDED]: ProductUpdateProps
  [SHOPPING_EVENT_NAMES.PRODUCT_REMOVED]: ProductUpdateProps
  [SHOPPING_EVENT_NAMES.PRODUCT_VIEWED]: TrackingProduct
  [SHOPPING_EVENT_NAMES.CART_VIEWED]: CartViewedProps
  [SHOPPING_EVENT_NAMES.PRODUCT_SHARED]: ProductSharedProps
}