import { Collection, GatsbyShopifyStorefrontProduct, IProduct } from "shopify-buy"
import { ProductCopy, SectionTheme } from "types/misc"
import { PARTNER_PRODUCT_HANDLES } from '../../common/config/products';
import { sortVariantsByPrice, transformShopifyMediaImages } from "./shopify";
import sanityClient from '@sanity/client'

export const clientSanity = sanityClient({
  projectId: String(process.env.GATSBY_SANITY_PROJECT),
  dataset: String(process.env.GATSBY_SANITY_DATASET),
  apiVersion: '2021-03-25', // use current UTC date - see "specifying API version"!
  useCdn: true,
})

export const organizeByHandle = (copyProducts: any): { [key: string]: ProductCopy } => {
  const organized = {}

  for (const product of copyProducts) {
    if (!product.node.excludedFromCollection) {
      organized[product.node.handle] = product.node;
    }
  }

  return organized
}

type CollectionEdges = {
  edges: {
    node: Collection
  }[]
}

export interface ShopifyCopyProduct extends IProduct {
  copy: ProductCopy;
  storefrontId?: string;
}

export interface ShopifyCopyStorefrontProduct extends GatsbyShopifyStorefrontProduct {
  copy: ProductCopy;
}


export interface ShopifyCopyCollection extends Collection {
  products: ShopifyCopyProduct[]
}

type TransformCollectionParams = {
  collections: CollectionEdges
  sectionReference: {
    _type?: 'productCollectionSection',
    collectionHandle: string
    aggregatedCollection?: ShopifyCopyCollection
  }[]
  allSanityProductPage: {
    edges: ProductCopy[]
  }
}

export const mergeSanityProductWithShopifyProduct = (sanityProducts: ProductCopy[], shopifyProducts: IProduct[]) => {
  const productsFormatted: ShopifyCopyProduct[] = [];
  for (const product of shopifyProducts) {
    for (const sanityProduct of sanityProducts) {
      if (sanityProduct.handle === product.handle) {
        productsFormatted.push({
          ...product,
          copy: sanityProduct
        })
      }
    }
  }

  return productsFormatted;
}

export const organizeCollectionByHandle = (collections: CollectionEdges): { [key: string]: Collection } => {
  const organized = {}

  for (const collection of collections.edges) {
    if (collection.node.handle) {
      organized[collection.node.handle] = collection.node;
    }
  }

  return organized
}

export const transformSanityCollectionSections = (data: TransformCollectionParams): any[] => {
  const sections =  [...data.sectionReference];

  if (data.collections.edges.length) {
    const organized = organizeCollectionByHandle(data.collections);
    const productsOrganized = organizeByHandle(data.allSanityProductPage.edges);

    if (Object.keys(organized).length > 0) {
      for (const section of sections) {
        if (
          section._type === "productCollectionSection"
          && organized[section.collectionHandle]
        ) {
          if (Object.keys(productsOrganized).length > 0) {
            for (let index = 0; index < organized[section.collectionHandle].products.length; index++) {
              const productTransformed = { 
                ...organized[section.collectionHandle].products[index],
                variants: sortVariantsByPrice(organized[section.collectionHandle].products[index].variants) 
              } as ShopifyCopyProduct;
              productTransformed.copy = productsOrganized[productTransformed.handle];
              organized[section.collectionHandle].products[index] = transformShopifyMediaImages(
                productTransformed, 
                { 
                  width: 410, 
                  height: 410 
                }
              );
            }
          }

          section.aggregatedCollection = organized[section.collectionHandle] as ShopifyCopyCollection;
        }
      }
    }
  }

  return sections;
}

export const filterVariantsBySanityFilterSku = (shopifyCopyProduct: ShopifyCopyProduct, productHandle = PARTNER_PRODUCT_HANDLES.WEATHERFLOW_TEMPEST) => {
  const copyProduct = { ...shopifyCopyProduct };
  if (
    shopifyCopyProduct.handle === productHandle
    && shopifyCopyProduct?.copy?.filterSku
  ) {
    const filterSku = shopifyCopyProduct.copy.filterSku || '';
    copyProduct.variants = shopifyCopyProduct.variants.filter(v => v.sku !== filterSku);
  }

  return copyProduct;
}