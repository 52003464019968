import { useCheckout, useDiscount } from "global";
import React from "react";
import { isBundleInCart } from "../../lib/pickystory";
import styled from "styled-components";
import { isFreeGiftApplied, isProductDiscounted, isTieredDiscountApplied } from "utilities";
import { IProductVariant } from "shopify-buy";
import { Discount, ProductCopy } from "types/misc";

type TextProps = {
  text?: string
  displayDiscount?: boolean
  discount?: Discount
}
const BadgeDiscountText = ({
  text,
  displayDiscount,
  discount
}: TextProps) => {
  if (displayDiscount && discount) {
    return (
      <span>
        {(discount.amount && discount.amount > 0) ? '$' : ''}
        {(discount.amount && Number(discount.amount).toFixed(2)) || discount.percentage}
        {(discount.percentage && discount.percentage > 0) ? '%' : ''}
        {" "}OFF
      </span>
    )
  }

  return (
    <span>
      {text ? text : 'On Sale!'}
    </span>
  )
}


type Props = {
  product: {
    variants: IProductVariant[]
    copy: ProductCopy
  }
}
const ProductBadge = ({ product }: Props) => {
  const { discount } = useDiscount();
  const { checkout } = useCheckout();

  const isOnSale = isProductDiscounted(discount, product) 
    && !isBundleInCart(checkout) 
    && !Boolean(product.copy.tieredDiscountCode)
    && !isTieredDiscountApplied(checkout)
    && !isFreeGiftApplied(checkout);

  return (
    <BadgeContainer color={product.copy.badgeColor?.hex} isOnSale={isOnSale} className="product-badge">
      {
        isOnSale
        ? (
          <BadgeDiscountText
            text={product.copy.badgeSaleText}
            displayDiscount={product.copy.badgeDisplayDiscount}
            discount={discount}
          />
        )
        : (
          <span>
            {product.copy.badgeText}
          </span>
        )
      }
    </BadgeContainer>
  )
}

export const ProductBadgeSimple = ({ productPage }: { productPage: ProductCopy }) => {
  return (
    <BadgeContainer color={productPage.badgeColor?.hex} className="product-badge">
      <span>
        {productPage.badgeText}
      </span>
    </BadgeContainer>
  )
}

const BadgeContainer = styled.div<{ color?: string, isOnSale?: boolean }>`
  background-color: ${({ color, isOnSale }) => {
    if (isOnSale) return 'var(--secondaryColor)'
    if (color) return color;
    return 'var(--primaryColor)'
  }};
  line-height: 2.2rem;
  padding: 8px 20px;
  color: white;
  font-size: 12px;
  border-radius: 25px;
  text-align: center;
  z-index: 1;
  span {
    text-transform: uppercase;
  }

  @media (max-width: 480px) {
    padding: 4px 10px;
    font-size: 10px;
    line-height: 2rem;
  }
`

export default ProductBadge