import React from "react";
import { Button } from "@rachio-npm/rachio-retail-common";
import { Dropdown } from "components/elements";
import { Price } from "components/product/Price";
import { PickyStorySelectionMap, PickyStoryDeal, deriveDiscountAmount } from "../../../../lib/pickystory";
import { ShopifyCopyStorefrontProduct, fetchIdFromGlobalId } from "utilities";
import styled, { css } from "styled-components";
import { BundleBuilderSelectionCard } from "./BundleBuilderSelectionCard";
import { below } from "styles";

type SelectionDisplayProps = {
  selectionMap: PickyStorySelectionMap | null,
  deal: PickyStoryDeal,
  products: (ShopifyCopyStorefrontProduct | null)[],
  subTotal: number,
  onAddToCart: () => void,
  onRemoveSelection: (strippedProductId: string) => void,
  onUpdateQuantitySelection: (strippedProductId: string, quantity: number) => void
  buttonText: string,
  addToCartStatus: "PENDING" | "IDLE",
  staticOnMobile?: boolean
}
export const BundleBuilderSelectionDisplay = ({ 
  deal, 
  selectionMap, 
  products, 
  onAddToCart, 
  subTotal, 
  onRemoveSelection,
  buttonText,
  addToCartStatus,
  onUpdateQuantitySelection,
  staticOnMobile
}: SelectionDisplayProps) => {
  const selections = selectionMap ? Object.keys(selectionMap) : [];
  const selectionProducts = products.filter(p => p && selections.includes(fetchIdFromGlobalId(p.id)))
  const selectionQtyTotal = selectionMap ? Object.keys(selectionMap).reduce((prev, cur) => prev + selectionMap[cur].quantity, 0) : 0;

  const canApplyDiscount = deal.spec.discount?.prerequisiteQuantity && 
    selectionQtyTotal >= deal.spec.discount.prerequisiteQuantity &&
    selectionQtyTotal <= deal.spec.maxQuantity;

  const discountAmountText = () => {
    if (!deal.spec.discount?.prerequisiteQuantity) return null;

    if (selectionQtyTotal < deal.spec.discount.prerequisiteQuantity) {
      return `Add ${deal.spec.discount.prerequisiteQuantity - selectionQtyTotal} more product(s) to unlock a discount!`
    }

    if (selectionQtyTotal > deal.spec.maxQuantity) {
      return `You can only have ${deal.spec.maxQuantity} products in this bundle. Please update your selections!`
    }

    return `Discount Unlocked!`
  }

  return (
    <Container 
      data-testid="bundle-builder-selection-display"
      className="bundle-builder-selection-display"
      staticOnMobile={staticOnMobile}
    >
      <div className="inner-wrapper">
        <h3 className="bundle-builder-selection-display-header">
          <span>
            Your Bundle
          </span>
          <span>
            {selectionQtyTotal} Items
          </span>
        </h3>
        
        {selectionMap && selectionProducts && 
          <Dropdown testId="bundle-selection-dropdown">
            <SelectionCardList>
              {selectionProducts.map((product, index) => (
                <BundleBuilderSelectionCard 
                  key={`selection-card-${index}`}
                  product={product}
                  onRemoveSelection={onRemoveSelection} 
                  onUpdateQuantitySelection={onUpdateQuantitySelection}
                  selectionMap={selectionMap}
                />
              ))}
            </SelectionCardList>
          </Dropdown>
        }
        <div>
          <strong data-testid="bundle-discount-text" className="bundle-builder-discount-text">{discountAmountText()}</strong>
          {selections.length <= deal.spec.maxQuantity && (
            <ProgressBar
              prerequisiteQuantity={deal.spec.discount?.prerequisiteQuantity || 0}
              selectionProductsQuantity={selectionProducts.length}
            />
          )}
        </div>
        <AddToCartDetails>
          {deal.spec.discount?.prerequisiteQuantity && (
            <Price 
              basePrice={selections.length ? subTotal: 0}
              discountAmount={canApplyDiscount ? deriveDiscountAmount(deal, subTotal) : 0}
              forceShow={selectionQtyTotal >= deal.spec.discount.prerequisiteQuantity &&
                selectionQtyTotal <= deal.spec.maxQuantity}
            />
          )}
          <Button 
            data-testid="add-to-cart-button" 
            disabled={addToCartStatus === "PENDING" || !selections.length} 
            onClick={onAddToCart}
          >
            {buttonText}
          </Button>
        </AddToCartDetails>
      </div>
    </Container>
  )
}

const Container = styled.div<{ staticOnMobile?: boolean }>`
  text-align: center;
  padding: 0 20px 20px 20px;
  margin: 0 auto 20px;
  .inner-wrapper {
    margin: 0 auto;
    width: 320px;
    max-width: 100%;
    background: white;
    padding: 10px;
    border-radius: 10px;
  }

  .bundle-builder-selection-display-header {
    display: flex;
    justify-content: space-between;
    padding: 0 0 20px 0;
    margin: 0;
    font-size: var(--h4);
  }

  .bundle-builder-discount-text {
    display: block;
    font-size: 1.4rem;
    line-height: 20px;
    margin: 20px auto 10px;
  }

  ${below.large`
    .bundle-builder-selection-display-header {
      display: none;
    }

    &.bundle-builder-selection-display {
      position: fixed !important;
      top: unset !important;
      width: 100% !important;
      height: auto !important;
      z-index: 1;
      bottom: 0;
      left: 0;
      background: var(--white);
      margin: 0;
      .inner-wrapper {
        padding-top: 20px;
        background: transparent;
        border-radius: 0;
      }
    }

    ${({ staticOnMobile }) => staticOnMobile && css`
      &.bundle-builder-selection-display {
        position: static !important;
        background: transparent;
      }
    `}
  `}
`

const SelectionCardList = styled.div`
  margin: 10px auto;
  align-items: center;
  > div {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
`

const AddToCartDetails = styled.div`
  .price-text {
    margin: 1rem auto;
    .price, 
    .discount {
      font-size: 1.8rem;
      font-weight: bold;
    }
    .price {
      margin-right: .5em;
      color: inherit;
    }
    .discount {
      color: var(--accentColor);
    }
    display: flex;
    justify-content: center;
  }
  button {
    font-size: .8em;
    margin 0 auto;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    background: var(--accentColor);
    border-color: var(--accentColor);
    &:hover {
      background: var(--primaryColor);
      border-color: var(--primaryColor);
    }
  }
`

const ProgressBar = styled.div<{ prerequisiteQuantity: number, selectionProductsQuantity: number }>`
  height: 10px;
  background: lightgray;
  margin: 5px auto 10px;
  border-radius: 50px;
  position: relative;
  &:after {
    content: " ";
    border-radius: 50px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${({ prerequisiteQuantity, selectionProductsQuantity }) => {
      if (selectionProductsQuantity >= prerequisiteQuantity) return '100%';
      return `calc((${selectionProductsQuantity} / ${prerequisiteQuantity}) * 100%)`
    }};
    background: var(--primaryColor);
  }
`