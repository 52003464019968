import { sendPageView } from "./common/analytics";

function scrollToProduct() {
  const search = new URLSearchParams(window.location.search);
  const productSectionId = search.get('scrollTo');

  if (productSectionId) {
    const element = document.getElementById(productSectionId)
    setTimeout(() => {
      window.scrollTo({
        behavior: 'smooth',
        top: element ? element.offsetTop - 175 : 0,
      })
    }, 100)
  }
}

export const onClientEntry = async () => {
  // IntersectionObserver polyfill for gatsby-image (Safari, IE)
  if (typeof IntersectionObserver === `undefined`) {
    await import('intersection-observer');
  }
}

/**
 * Called when the user changes routes, INCLUDING on the initial load of the app
 * @src https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#onRouteUpdate
 *
 * @typedef {Object}
 * @property {Location} location
 * @property {Location} prevLocation
 */
export const onRouteUpdate = ({ location, prevLocation }) => {
  /**
   * @src https://gomakethings.com/fixing-safaris-back-button-browser-cache-issue-with-vanilla-js/
   * If browser back button was used, flush cache
   * This ensures that user will always see an accurate, up-to-date view based on their state
   * https://stackoverflow.com/questions/8788802/prevent-safari-loading-from-cache-when-back-button-is-clicked
   *
   * @url onpageshow - https://developer.mozilla.org/en-US/docs/Web/API/Window/pageshow_event
   *
   * @param {PageTransitionEvent} event
   */
  window.onpageshow = function (event) {
    if (event.persisted) {
      window.location.reload();
    }
  };

  if (`requestAnimationFrame` in window) {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => sendPageView(location, prevLocation))
    })
  } else {
    // simulate 2 rAF calls
    setTimeout(() => sendPageView(location, prevLocation), 32)
  }

  scrollToProduct();

  return null
}