import React, { useEffect, useState } from "react";
import { ICart, IProduct, IProductVariant } from "shopify-buy";
import { PARTNER_CONTROLLER_PRODUCT_SKUS } from "../../../../../common/config/products";
import {
  fetchIdFromGlobalId,
  getGlobalVariantId,
  getProductByHandle,
  mergeSanityProductWithShopifyProduct,
  ShopifyCopyStorefrontProduct,
  transformShopifyStorefrontImagesToGatsbyImage
} from "utilities";
import {
  filterAvailablePickyStoryVariants,
  PickyStoryDeal,
  PickyStoryProduct,
  PickyStoryProductPartial,
  PickyStorySelectionMap,
  getVariantsFromSeletions,
  usePickyStoryContext,
} from "../../../../lib/pickystory";
import { BUNDLE_LAYOUTS, BundleCopyProduct, ProductCopy, SectionPaddingSize, SectionSize, SectionTheme } from "types/misc";
import { useCheckout } from "global";
import { flattenProductVariants } from "../../../../lib/shopify";
import { TrackingProductUnformatted, trackProductAdded, trackProductViewed } from "analytics";
import { BundleUnavailable } from "../BundleUnavailable";
import { BundleBuilderProductListOverview } from "./BundleBuilderProductListOverview";
import { BundleBuilderSelectionDisplay } from "./BundleBuilderSelectionDisplay";
import { Section } from "components/sections";
import { PortableText } from "components/elements";
import styled, { css } from "styled-components";
import { below } from "styles";

const trackProducts = (
  variants: { id: number, price: number }[],
  products: ShopifyCopyStorefrontProduct[],
  checkout: ICart,
  bundleName: string,
  trackingEvent: 'added' | 'viewed'
) => {
  const variantIds = variants.map(v => getGlobalVariantId(v.id));
  const trackingItems: (TrackingProductUnformatted | undefined)[] = products.map(p => {
    const variant = p.variants.find(v => variantIds.includes(String(v.id)));
    if (variant) {
      return {
        price: variant.price.amount,
        sku: variant.sku,
        variantId: variant.id,
        productId: p.id,
        title: p.title,
        quantity: 1,
        bundle: bundleName
      } as TrackingProductUnformatted
    }
  });

  trackingItems.forEach(trackingItem => {
    if (!trackingItem) return;
    if (trackingEvent === 'added') {
      trackProductAdded(trackingItem, checkout);
    } else {
      trackProductViewed(trackingItem);
    }
  });
}

type TransformedProductData = {
  products?: ShopifyCopyStorefrontProduct[],
  dealProducts?: PickyStoryProductPartial[]
};
const transformProducts = async (pickyStoryProducts: PickyStoryProduct[], copyProducts: ProductCopy[]): Promise<TransformedProductData> => {
  const products = await Promise.all(
    pickyStoryProducts.map(p => getProductByHandle(p.handle))
  )

  if (!products) {
    throw new Error('No Products Found!');
  }

  const nullProductsRemoved = products.filter(Boolean) as IProduct[];

  const allProductsOutOfStock = nullProductsRemoved.filter(p => !p.availableForSale).length === nullProductsRemoved.length;

  if (allProductsOutOfStock) {
    throw new Error('Bundle Product Out-of-Stock');
  }

  const productsFormatted = mergeSanityProductWithShopifyProduct(
    copyProducts,
    nullProductsRemoved
  )
    .map(product => {
      const productWithFormattedImages = transformShopifyStorefrontImagesToGatsbyImage(product, 600, 600) as ShopifyCopyStorefrontProduct;
      productWithFormattedImages.variants = productWithFormattedImages.variants.filter(v => v.sku !== PARTNER_CONTROLLER_PRODUCT_SKUS.WEATHERFLOW_TEMPEST_SINGLE_PURCHASE)
      return productWithFormattedImages
    })
    .filter(p => !p.copy.excludedFromCollection && p.availableForSale)

  const flattenedProductVariants = flattenProductVariants(productsFormatted, true);

  const dealProductsFilteredVariants = pickyStoryProducts.map((p) => filterAvailablePickyStoryVariants(p, flattenedProductVariants));

  return {
    products: productsFormatted,
    dealProducts: dealProductsFilteredVariants
  };
}

type BundleDisplayProps = {
  deal: PickyStoryDeal
  copyProducts: ProductCopy[]
  copy: BundleCopyProduct
  layout?: BUNDLE_LAYOUTS
  onBundleOutOfStock?: (errorMessage: string) => void
  hideTitle?: boolean,
  theme?: SectionTheme,
  paddingTop?: SectionPaddingSize
  paddingBottom?: SectionPaddingSize,
  size?: SectionSize,
  staticOnMobile?: boolean
}

enum ADD_TO_CART_STATES {
  IDLE = "IDLE",
  PENDING = "PENDING",
}

export const BundleBuilderDisplay = ({
  deal,
  copy,
  copyProducts,
  onBundleOutOfStock,
  hideTitle,
  theme,
  paddingBottom,
  paddingTop,
  size,
  staticOnMobile
}: BundleDisplayProps) => {
  usePickyStoryContext();
  const [outOfStock, setOutOfStock] = useState<boolean>(false);
  const [products, setProducts] = useState<(ShopifyCopyStorefrontProduct | null)[]>([]);
  const [selectionMap, setSelectionMap] = useState<PickyStorySelectionMap | null>(null);
  const [addToCartStatus, setAddToCartStatus] = useState<ADD_TO_CART_STATES>(ADD_TO_CART_STATES.IDLE);

  const [subTotal, setSubTotal] = useState<number | null>(null);
  const { checkout } = useCheckout();

  const onVariantSelect = (globalProductId: string, variant: IProductVariant) => {
    const strippedProductId = Number(fetchIdFromGlobalId(globalProductId));
    const product = deal.products.find(p => p.id === strippedProductId);
    if (!product) return;
    const copySelectionMap = selectionMap ? { ...selectionMap } : {};

    copySelectionMap[product.id] = {
      option1: variant.selectedOptions[0].value,
      option2: variant.selectedOptions[1]?.value,
      option3: variant.selectedOptions[2]?.value,
      position: product.position,
      quantity: 1
    };

    sessionStorage.setItem(deal.id, JSON.stringify(copySelectionMap));

    setSelectionMap(copySelectionMap);
  }

  const onRemoveSelection = (strippedProductId: string) => {
    const copySelection = {...selectionMap};
    delete copySelection[strippedProductId];
    if (!Object.keys(copySelection).length) {
      sessionStorage.removeItem(deal.id)
      return setSelectionMap(null);
    }
    sessionStorage.setItem(deal.id, JSON.stringify(copySelection))
    setSelectionMap(copySelection);
  }

  const onUpdateQuantitySelection = (strippedProductId: string, quantity: number) => {
    const copySelection = {...selectionMap};
    copySelection[strippedProductId] = {
      ...copySelection[strippedProductId],
      quantity
    }
    sessionStorage.setItem(deal.id, JSON.stringify(copySelection))
    setSelectionMap(copySelection);
  }

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const { products, dealProducts } = await transformProducts(deal.products, copyProducts);
        if (products && dealProducts) {
          setProducts(products);
        }
      } catch (error) {
        const err = error as Error;
        console.error(err);
        setOutOfStock(true);
        if (typeof onBundleOutOfStock === "function") {
          onBundleOutOfStock(err.message);
        }
      }
    }

    fetchProductData();

    const selectionsStorage = sessionStorage.getItem(deal.id);

    if (selectionsStorage) {
      const selectionStorageParsed = JSON.parse(selectionsStorage);
      setSelectionMap(selectionStorageParsed);
    }
  }, [])

  const addBundleToCart = async () => {
    try {
      if (!selectionMap) return;

      setAddToCartStatus(ADD_TO_CART_STATES.PENDING);
      const dealVariants = getVariantsFromSeletions(deal, selectionMap);
      if (!dealVariants) throw new Error('Could not get PickyStory deal variants');

      await deal.addVariantsToCart(dealVariants, 1);

      trackProducts(
        dealVariants,
        products as ShopifyCopyStorefrontProduct[],
        checkout,
        deal.spec.name,
        'added'
      );

      sessionStorage.removeItem(deal.id);

      /**
       * @src location.href does not work on iPad - https://stackoverflow.com/questions/26439843/window-location-href-doesnt-work-in-ipad-and-iphone
       * @src typing workaround for window.location -  https://github.com/microsoft/TypeScript/issues/48949
       */
      (window as Window).location = window.location.origin + '/cart/'
    } catch (error) {
      console.error('Could not add Bundle to Cart', error);
    }
  }

  useEffect(() => {
    if (!selectionMap) return;
    const dealVariants = getVariantsFromSeletions(deal, selectionMap);
    const total = dealVariants.reduce((prev, cur) => prev + (cur.price * cur.quantity), 0);

    setSubTotal(total);

    trackProducts(
      dealVariants,
      products as ShopifyCopyStorefrontProduct[],
      checkout,
      deal.spec.name,
      'viewed'
    );
  }, [selectionMap]);

  const buttonText = () => {
    switch (addToCartStatus) {
      case ADD_TO_CART_STATES.PENDING:
        return "Adding to Cart..."
      default:
        return "Add to Cart"
    }
  }

  if (outOfStock) {
    return <BundleUnavailable />
  }

  return (
    <Section
      id='product-bundle-section'
      paddingTop={paddingTop || 'small'}
      theme={theme || 'white'}
      paddingBottom={paddingBottom || 'small'}
      size={size || 'small'}
    >
      {!hideTitle && (
        <TopSection style={{ textAlign: 'center' }}>
          <h1 className="no-top-margin">
            {copy.title}
          </h1>
          <div className="bundle-product-description">
            <PortableText _rawContent={copy._rawDescription} />
          </div>
        </TopSection>
      )}
      <BundleBuilderLayout staticOnMobile={staticOnMobile}>
        <BundleBuilderProductListOverview
          products={products}
          onVariantSelect={onVariantSelect}
          selectionMap={selectionMap}
        />
        <BundleBuilderSelectionDisplay 
          deal={deal}
          selectionMap={selectionMap}
          products={products}
          onAddToCart={addBundleToCart} 
          subTotal={subTotal || 0}
          onRemoveSelection={onRemoveSelection}
          buttonText={buttonText()}
          addToCartStatus={addToCartStatus}
          staticOnMobile={staticOnMobile}
          onUpdateQuantitySelection={onUpdateQuantitySelection}
        />
      </BundleBuilderLayout>
    </Section>
  )
}

const BundleBuilderLayout = styled.div<{ staticOnMobile?: boolean }>`
  display: flex;
  justify-content: space-between;
  .bundle-builder-selection-display {
    position: sticky;
    top: 20%;
    height: 400px;
  }

  ${below.large`
    ${({ staticOnMobile }) => staticOnMobile && css`
      flex-wrap: wrap;
      .bundle-builder-selection-display {
        order: 1;
        background: transparent;
      }
      .Section--product-bundle-builder {
        order: 2;
      }
    `}
  `}
`

const TopSection = styled.div`
  .bundle-product-description {
    max-width: 992px;
    margin: 2rem auto 3rem;
  }
`